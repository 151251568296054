import { useTheme } from "@emotion/react";
import { Col, ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { Box, Card } from "@mui/material";
import { Image } from "../component_library/image";
import { Text } from "../component_library/text";
import React from "react";
import { Link } from "react-router-dom";
import { DisasterBlasterWikiBanner } from "../components/disasterblaster_wiki_banner";
import { ContactInfoSection } from "../components/contact_info_section";
import { Localization } from "../component_library/localization";
import { Helmet } from "react-helmet";
import { IMAGE_PUBLIC_PATH } from "../constants";

const backgroundColor1 = "#211132";
const cardColor1 = "#72499F";

const backgroundColor2 = "#352255";
const cardColor2 = "#9562B8";

const CardTypes = {
    DAMAGE: "Damage",
    ELEMENT: "Element",
    SHOOTING_CHANGER: "Shooting Changer",
    EFFECT: "Effect",
    FLOW: "Flow",
};

function getFlowCards() {
    return [
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/increase_speed_card_icon.png`,
            body: Localization.get("db_wiki_cards_increase_speed"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/restart_card_icon.png`,
            body: Localization.get("db_wiki_cards_restart"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/step_back_1_card_icon.png`,
            body: Localization.get("db_wiki_cards_step_back_1"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/step_back_2_card_icon.png`,
            body: Localization.get("db_wiki_cards_step_back_2"),
        }
    ];
}

function getDamageCards() {
    return [
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/add_damage_card_icon.png`,
            body: Localization.get("db_wiki_cards_add_damage"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/damage_multiplier_card_icon.png`,
            body: Localization.get("db_wiki_cards_damage_multiplier"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_effect_neighbors_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_effect_neighbors"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_element_neighbors_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_element_neighbors"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_flow_neighbors_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_flow_neighbors"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_shooting_changer_neighbors_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_shooting_changer_neighbors"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_electricity_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_electricity"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_fire_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_fire"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_ice_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_ice"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_water_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_water"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/multiply_damage_by_position_card_icon.png`,
            body: Localization.get("db_wiki_cards_multiply_damage_by_position"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/specialist_multiplier_card_icon.png`,
            body: Localization.get("db_wiki_cards_specialist_multiplier"),
        },
    ];
}

function getElementCards() {
    return [
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/fire_card_icon.png`,
            body: Localization.get("db_wiki_cards_fire"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/water_card_icon.png`,
            body: Localization.get("db_wiki_cards_water"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/ice_card_icon.png`,
            body: Localization.get("db_wiki_cards_ice"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/electricity_card_icon.png`,
            body: Localization.get("db_wiki_cards_electricity"),
        },
    ];
}

function getShootingChangerCards() {
    return [
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/bigger_bullet_card_icon.png`,
            body: Localization.get("db_wiki_cards_bigger_bullet"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/bullet_sequence_card_icon.png`,
            body: Localization.get("db_wiki_cards_bullet_sequence"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/bullet_split_card_icon.png`,
            body: Localization.get("db_wiki_cards_bullet_split"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/meteor_card_icon.png`,
            body: Localization.get("db_wiki_cards_meteor"),
        },
    ];
}

function getEffectCards() {
    return [
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/bounce_card_icon.png`,
            body: Localization.get("db_wiki_cards_bounce"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/drill_card_icon.png`,
            body: Localization.get("db_wiki_cards_drill"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/explosion_card_icon.png`,
            body: Localization.get("db_wiki_cards_explosion"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/insta_death_card_icon.png`,
            body: Localization.get("db_wiki_cards_insta_death"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/bullet_cluster_card_icon.png`,
            body: Localization.get("db_wiki_cards_bullet_cluster"),
        },
        {
            image: `${IMAGE_PUBLIC_PATH}/cards/pacman_card_icon.png`,
            body: Localization.get("db_wiki_cards_pacman"),
        },
    ];
}

function IntroductionSection() {

    return (
        <ContentPanel color={backgroundColor1}>
            <ContentPanelTitle>
                {Localization.get("db_wiki_cards_title")}
            </ContentPanelTitle>
            <Paragraph>
                <ParagraphBody>
                    {Localization.get("db_wiki_cards_intro")}
                    <ul>
                        <li>
                            <Link to="#flow-cards-section">
                                {Localization.get("db_wiki_cards_flow_title")}
                            </Link>
                        </li>
                        <li>
                            <Link to="#damage-cards-section">
                                {Localization.get("db_wiki_cards_damage_title")}
                            </Link>
                        </li>
                        <li>
                            <Link to="#element-cards-section">
                                {Localization.get("db_wiki_cards_element_title")}
                            </Link>
                        </li>
                        <li>
                            <Link to="#effect-cards-section">
                                {Localization.get("db_wiki_cards_effect_title")}
                            </Link>
                        </li>
                        <li>
                            <Link to="#shooting-changer-cards-section">
                                {Localization.get("db_wiki_cards_shooting_changer_title")}
                            </Link>
                        </li>
                    </ul>
                </ParagraphBody>
            </Paragraph>
        </ContentPanel>
    );
}

function getCardColorByType(cardType) {
    const damageCardColor = "#FF0000";
    const elementCardColor = "#FFFF00";
    const shootingChangerCardColor = "#00FFFF";
    const effectCardColor = "#00FF00";
    const flowCardColor = "#BBBBBB";

    const imageColor = cardType === CardTypes.DAMAGE ? damageCardColor :
        cardType === CardTypes.ELEMENT ? elementCardColor :
            cardType === CardTypes.SHOOTING_CHANGER ? shootingChangerCardColor :
                cardType === CardTypes.EFFECT ? effectCardColor :
                    cardType === CardTypes.FLOW ? flowCardColor : "#000000";

    return imageColor;
}


function CardDescriptionCard({ cardType, image, body, backgroundColor }) {

    const theme = useTheme();
    const imageColor = getCardColorByType(cardType);
    const isDamageType = cardType === CardTypes.DAMAGE;
    const isFlowType = cardType === CardTypes.FLOW && !image.includes("increase_speed_card_icon");
    const colorImage = !image.includes("neighbor");

    return (
        <Card
            elevation={20}
            sx={{
                borderRadius: "1rem",
                backgroundColor: backgroundColor ? backgroundColor : theme.palette.background.paper,
                height: "100%",
                transition: "transform 0.2s ease-in-out", // Aggiungi la transizione
                '&:hover': {
                    transform: "scale(1.05) translateY(-10px)", // Scala e trasla verso l'alto con l'hover
                },
            }}
        >
            <Row weights={[4, 8]} center={false}>
                <Box sx={{
                    position: 'relative',
                    backgroundColor: "#111111",
                    borderStyle: "solid",
                    borderColor: imageColor,
                    borderWidth: "5px",
                    boxShadow: "0 0 1rem black",
                    overflow: 'hidden',
                }} >
                    <Image src={image} />
                    {colorImage && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: imageColor,
                                mixBlendMode: 'multiply',
                            }}
                        />
                    )}
                    {isDamageType && (
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                color: 'white',
                                fontSize: '1.2rem',
                            }}
                        >
                            N
                        </Box>
                    )}
                    {isFlowType && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                fontSize: '1.2rem',
                            }}
                        >
                            N
                        </Box>
                    )}
                </Box>
                <Paragraph>
                    <Text variant={"h6"} center={false}>
                        {toHTML(body)}
                    </Text>
                </Paragraph>
            </Row>
        </Card>
    );
}

function CardTypeSection({ title, body, backgroundColor, children }) {

    const theme = useTheme();
    const rowElementsCount = 2;
    const rows = [];
    const cards = React.Children.toArray(children);

    for (let i = 0; i < cards.length; i += rowElementsCount) {
        const row = [];
        for (let j = 0; j < rowElementsCount; j++) {
            const index = i + j;
            if (index < cards.length) {
                row.push(cards[index]);
            } else {
                row.push(<div key={`empty-${i}-${j}`} style={{ flex: 1, visibility: 'hidden' }} />);
            }
        }
        rows.push(row);
    }


    return (
        <ContentPanel color={backgroundColor ? backgroundColor : "#000000"}>
            <Row center={false}>
                <Paragraph>
                    <ParagraphTitle>
                        {toHTML(title)}
                    </ParagraphTitle>
                    <ParagraphBody>
                        {toHTML(body)}
                    </ParagraphBody>
                </Paragraph>
            </Row>
            {rows.map((row) => (
                <Row sx={{ backgroundColor: backgroundColor, py: "1rem" }}>
                    {row}
                </Row>
            ))}
        </ContentPanel>
    );
}

function DamageCardsSection({ backgroundColor, cardColor }) {

    const cardType = CardTypes.DAMAGE;

    return (
        <div id="damage-cards-section">
            <CardTypeSection
                backgroundColor={backgroundColor}
                title={Localization.get("db_wiki_cards_damage_title")}
                body={Localization.get("db_wiki_cards_damage_description")}
            >
                {getDamageCards().map((card) => (
                    <CardDescriptionCard
                        cardType={cardType}
                        image={card.image}
                        body={card.body}
                        backgroundColor={cardColor}
                    />
                ))}
            </CardTypeSection>
        </div>
    );
}

function ElementCardsSection({ backgroundColor, cardColor }) {

    const cardType = CardTypes.ELEMENT;

    return (
        <div id="element-cards-section">
            <CardTypeSection
                backgroundColor={backgroundColor}
                title={Localization.get("db_wiki_cards_element_title")}
                body={Localization.get("db_wiki_cards_element_description")}
            >
                {getElementCards().map((card) => (
                    <CardDescriptionCard
                        cardType={cardType}
                        image={card.image}
                        body={card.body}
                        backgroundColor={cardColor} />
                ))}
            </CardTypeSection>
        </div>
    );
}

function ShootingChangerCardSection({ backgroundColor, cardColor }) {

    const cardType = CardTypes.SHOOTING_CHANGER;

    return (
        <div id="shooting-changer-cards-section">
            <CardTypeSection
                backgroundColor={backgroundColor}
                title={Localization.get("db_wiki_cards_shooting_changer_title")}
                body={Localization.get("db_wiki_cards_shooting_changer_description")}
            >
                {getShootingChangerCards().map((card) => (
                    <CardDescriptionCard
                        cardType={cardType}
                        image={card.image}
                        body={card.body}
                        backgroundColor={cardColor} />
                ))}
            </CardTypeSection>
        </div>
    );
}

function EffectCardsSection({ backgroundColor, cardColor }) {

    const cardType = CardTypes.EFFECT;

    return (
        <div id="effect-cards-section">
            <CardTypeSection
                backgroundColor={backgroundColor}
                title={Localization.get("db_wiki_cards_effect_title")}
                body={Localization.get("db_wiki_cards_effect_description")}
            >
                {getEffectCards().map((card) => (
                    <CardDescriptionCard
                        cardType={cardType}
                        image={card.image}
                        body={card.body}
                        backgroundColor={cardColor} />
                ))}
            </CardTypeSection>
        </div>
    );
}

function FlowCardsSection({ backgroundColor, cardColor }) {

    const cardType = CardTypes.FLOW;

    return (
        <div id="flow-cards-section">
            <CardTypeSection
                backgroundColor={backgroundColor}
                title={Localization.get("db_wiki_cards_flow_title")}
                body={Localization.get("db_wiki_cards_flow_description")}
            >
                {getFlowCards().map((card) => (
                    <CardDescriptionCard
                        cardType={cardType}
                        image={card.image}
                        body={card.body}
                        backgroundColor={cardColor} />
                ))}
            </CardTypeSection>
        </div>
    );
}


export function DisasterBlasterWikiCardsPage() {
    const backgroundColor = "#211132";

    return (
        <>
            <Helmet>
                <title>Disaster Blaster Wiki - Cards</title>
                <meta name="description" content="This section breaks down the available cards in the demo, focusing on how each type affects weapon performance." />
                <link rel="canonical" href="https://www.jarsick.com/disasterblaster/wiki/cards" />
            </Helmet>
            <DisasterBlasterWikiBanner />
            <IntroductionSection />
            <FlowCardsSection backgroundColor={backgroundColor2} cardColor={cardColor2} />
            <DamageCardsSection backgroundColor={backgroundColor1} cardColor={cardColor1} />
            <ElementCardsSection backgroundColor={backgroundColor2} cardColor={cardColor2} />
            <EffectCardsSection backgroundColor={backgroundColor1} cardColor={cardColor1} />
            <ShootingChangerCardSection backgroundColor={backgroundColor2} cardColor={cardColor2} />
            <ContactInfoSection />
        </>
    );
}
